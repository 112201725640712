import React, {useState, useEffect} from 'react';

// Components
import CustomTextField from "components/CustomTextField";
import CustomButton from "components/CustomButton";
import CustomDropDown from "components/CustomDropdown";
//import CustomRadioButton from "components/CustomRadioButton";
import CustomCheckbox from "components/CustomCheckbox";

const agentTypeOptions = [
    {
        display: 'Lettings',
        value: 'Lettings'
    },
    {
        display: 'Sales',
        value: 'Sales'
    },    
    { display: 'Sales & Lettings', value: 'Sales & Lettings' }, 
    { display: 'Relocation Agents', value: 'Relocation Agents' },
    { display: 'Other', value: 'Other' }
];

// const lettingsListOptions = [
//     'Yes',
//     'No'
// ];

const termsAndConditionsOptions = [
    'Yes, I have read the Terms and Conditions.'
];


const Step3 = (props) => {
    return (
        <>
            <div className="form-fields-wrapper">
                <CustomTextField
                    type="text"
                    name="nego_name"
                    label="NEGOTIATOR FULL NAME *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomDropDown
                    name="agent_type"
                    label="AGENT TYPE *"
                    defaultOption={ agentTypeOptions[ 0 ] }
                    options={ agentTypeOptions }
                    variant="black-caret"
                    required={true}
                    handlechange={props.handlechange}
                // onChangeCallback={ onChange }
                />
                <CustomTextField
                    type="text"
                    name="job_title"
                    label="JOB TITLE *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="telephone"
                    label="TELEPHONE *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="mobile_no"
                    label="MOBILE *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="email"
                    name="email"
                    label="EMAIL (THIS WILL BE YOUR USERNAME) *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="password"
                    name="password"
                    label="CREATE PASSWORD *"
                    required={true}
                    handlechange={props.handlechange}
                    defaultValue=""
                />
                {/* <CustomRadioButton
                    isInline
                    variant="green-radio"
                    name="contact_lettings_list"
                    label="RECEIVE LETTINGS LIST"
                    options={ lettingsListOptions }
                    defaultOption={ lettingsListOptions[ 0 ] }
                    handlechange={props.handlechange}
                />
                <CustomRadioButton
                    isInline
                    variant="green-radio"
                    name="contact_sales_disposal_list"
                    label="Receive Sales Disposal List to the office email address"
                    options={ lettingsListOptions }
                    defaultOption={ lettingsListOptions[ 0 ] }
                    handlechange={props.handlechange}
                /> */}
                <CustomCheckbox
                    isInline={ false }
                    isLabelInline
                    name="terms_and_conditions"
                    options={ termsAndConditionsOptions }
                    defaultOption={""}
                    handlechange={props.handlechange}
                    customClass="mb-2"
                />
            </div>
            <div>
                <CustomButton
                    variant="green"
                    value="Submit"
                    type="submit"
                />
            </div>
        </>
    );
};

export default Step3;